import React from "react"
import { Link } from "gatsby"

const Card = ({ post, tab }) => {
  return (
    <div className='post'>
      {post.featuredImage && (
        <Link to={"/blog/posts/" + post.slug}>
          <img className='post__featured-image' alt='' src={post.featuredImage} />
        </Link>
      )}
      <div className="post__body">
        {
          tab && (
            <Link to={"/blog/tabs/" + tab.slug}>
              <p>{tab.title}</p>
            </Link>
          )
        }

        <Link to={"/blog/posts/" + post.slug}>
          <h2 className='post__title'>{post.title}</h2>
        </Link>

        <p className='post__summary'>{post.summary}</p>

        <div className="post__meta">
          {
            post.author.slug ? (
              <Link className='post__meta__author' to={"/blog/authors/" + post.author.slug}>
                {post.author.photo && <img alt='' src={post.author.photo} />}
                <p>{post.author.name}·&ndash; {post.publishedAt}</p>
              </Link>
            ) : <p className='post__meta__author'>{post.publishedAt}</p>
          }
          <p className='post__meta__readtime'>{post.readtime}</p>
        </div>
      </div>


    </div>
  )
}

export default Card
